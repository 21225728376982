import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      reloadInterval: null as ReturnType<typeof setTimeout> | null,
    };
  },

  created() {
    if (process.env.VUE_APP_CONTENT_REFRESH_INTERVAL) {
      this.reloadInterval = setInterval(async () => {
        const offset = window.scrollY;
        await this.reload();
        window.scrollTo(0, offset);
      }, +process.env.VUE_APP_CONTENT_REFRESH_INTERVAL);
    }
  },

  destroyed() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  },

  methods: {
    reload() {
      throw new Error('reload is not implemented');
    },
  },
});
